import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import lookBook from '../../../assets/sustainableFashionPage/look-book.jpg';

const Container = styled.div`
  position: relative;
`;

const Wrapper = styled.section`
  position: relative;
  margin-bottom: 0px;
  height: 400px;
`;

const Background = styled.img`
  position: fixed;
  height: 100%;
  width: 100%;
  object-fit: cover;
  top: ${({ theme }) => theme.mobileMenuBarHeightPixels};
`;

const WrapperText = styled.article`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.navy};
  text-align: center;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    padding: 0px 20px;
  }
`;

const Title = styled.h2`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 40px;
  line-height: 57px;
  letter-spacing: 8px;
  text-transform: uppercase;
  margin: 0px 0px 10px 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 25px;
    letter-spacing: 5px;
  }
`;

const Text = styled.p`
  max-width: 750px;
  font-family: Roboto;
  font-size: 18px;
  line-height: 26px;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  margin: 0px 0px 50px 0px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
    margin: 0px 0px 20px 0px;
  }
`;

const StyledLink = styled(Link)`
  border: 1.5px solid ${({ theme }) => theme.colors.navy};
  background-color: ${({ theme }) => theme.colors.navy};
  box-sizing: border-box;
  width: 183px;
  height: 54px;
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: 126px;
    height: 44px;
  }
`;

const LookBook = () => (
  <Container>
    <Background src={lookBook} />
    <Wrapper>
      <WrapperText>
        <Title>Lookbook v1</Title>
        <Text>
          Tilli lance son lookbook Printemps. Des inspirations de retouches, de réparations
          et d’upcycling qui mélangeront tous les styles, du plus simple au plus complexe.
        </Text>
        <StyledLink to="/lookbook-hiver-v1-2019-2020/">Découvrir</StyledLink>
      </WrapperText>
    </Wrapper>
  </Container>
);

export default LookBook;

import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';

import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/v3/Footer';
import Layout from '../../layouts/index';
import { Container, Content as StyledContent } from '../../components/home/homeStyledComponents';

import TopBar from '../../components/home/SustainableFashion/TopBar';
import LastCollab from '../../components/home/SustainableFashion/LastCollab';
import Advice from '../../components/home/SustainableFashion/Advice';
import LookBookBanner from '../../components/home/SustainableFashion/LookBookBanner';
import Advices from '../../components/home/SustainableFashion/Advices';
import OtherCollabs from '../../components/home/SustainableFashion/OtherCollabs';
import InstaFeed from '../../components/home/InstaFeed';
import Newsletter from '../../components/home/SustainableFashion/Newsletter';

import lastCollab from '../../assets/sustainableFashionPage/last-collab-beryl.png';
import advice from '../../assets/sustainableFashionPage/advice-of-the-moment.jpg';
import advices from '../../assets/sustainableFashionPage/advices.jpg';

const postDate = moment('2021-03-29');
const daysDiff = moment().diff(postDate, 'days');
const monthsDiff = moment().diff(postDate, 'months');
const dateSincePost = (monthsDiff >= 1) ? `${monthsDiff} mois` : `${daysDiff} jours`;

const Content = styled(StyledContent)`
  z-index: ${({ zIndex }) => zIndex ?? 1};
  ${({ extraTopPadding }) => extraTopPadding ? 'margin-top: -300px; padding-top: 300px;' : ''}
`;

// cSpell:ignore durablus
const topBarData = {
  title: 'Mode durable',
  text: `Expression féminine (latin : modus durablus) :
  prendre le temps de s’amuser et de laisser parler sa créativité
  pour donner une seconde vie à son dressing et son intérieur !`,
};

const currentAdviceData = {
  topTitle: 'Le conseil',
  topSubtitle: 'Du moment',
  img: advice,
  alt: 'Le conseil du moment',
  date: `Posté il y a ${dateSincePost}`,
  title: 'Customisez votre robe de mariée bohème à petit prix',
  text: `Pour le grand jour, vous rêvez d’une robe élégante, féminine et chic.
  Vous rêvez de dentelles, de voilages, de fluidité et de couronnes de fleurs.
  Et oui Mesdames, vous rêvez d’une robe de mariée au style bohème ! On vous
  explique comment vous pouvez avoir votre robe de rêve à un prix raisonnable
  et dans un esprit de mode responsable.`,
  link: "Voir l'article",
  url: '/la-rubrique/robe-de-mariee-boheme-pas-cher/',
};

const advicesData = {
  img: advices,
  alt: 'Les inspirations',
  title: 'Les Inspirations',
  text: `Découvrez toutes nos astuces pour prendre soin de votre dressing et
  remettre au goût du jour vos pièces favorites.`,
  link: 'Toutes les inspirations',
  url: '/la-rubrique/',
};

const SustainableFashionPage = () => (
  <Layout routeSlug="SustainableFashionPage">
    <Container>
      <Content extraTopPadding zIndex={2}>
        <MenuBar />
        <TopBar data={topBarData} />
        <LastCollab imgSrc={lastCollab} />
        <OtherCollabs />
      </Content>
      <LookBookBanner />
      <Content>
        <Advice data={currentAdviceData} />
        <Advices data={advicesData} />
        <InstaFeed />
        <Newsletter />
        <Footer />
      </Content>
    </Container>
  </Layout>
);

export default SustainableFashionPage;
